var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('filters-side-sheet',{attrs:{"expand-table-filters":_vm.expandTableFilters,"loading-data":_vm.loadingData,"filters-are-active":_vm.filtersAreActive},on:{"update:expandTableFilters":function($event){_vm.expandTableFilters=$event},"update:expand-table-filters":function($event){_vm.expandTableFilters=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('general.filters.received'))+" ")]},proxy:true},{key:"filters",fn:function(){return [_c('integration-filters',{attrs:{"context":_vm.viewContext,"loading-data":_vm.loadingData,"expand-table-filters":_vm.expandTableFilters,"active-filters":_vm.activeFilters},on:{"update:expandTableFilters":function($event){_vm.expandTableFilters=$event},"update:expand-table-filters":function($event){_vm.expandTableFilters=$event},"update:activeFilters":function($event){_vm.activeFilters=$event},"update:active-filters":function($event){_vm.activeFilters=$event}}})]},proxy:true}])}),_c('table-toolbar',{attrs:{"context":_vm.tableToolbarContext,"loading-data":_vm.loadingData,"expand-table-filters":_vm.expandTableFilters,"filters-are-active":_vm.filtersAreActive},on:{"update:expandTableFilters":function($event){_vm.expandTableFilters=$event},"update:expand-table-filters":function($event){_vm.expandTableFilters=$event},"refreshData":_vm.getTableData}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtersAreActive),expression:"filtersAreActive"}]},[_c('filter-chips',{attrs:{"active-filters":_vm.activeFilters},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('integration.' + item))+" ")]}}])})],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{style:(_vm.tableWrapStyle),attrs:{"loading":_vm.loadingData,"headers":_vm.headers,"items":_vm.tableData.items,"options":_vm.paginationOptions,"server-items-length":_vm.tableData.itemCount,"footer-props":{
              itemsPerPageOptions: _vm.paginationOptions.itemsRange,
              showCurrentPage: true,
              showFirstLastPage: true,
              disablePagination: _vm.loadingData,
              disableItemsPerPage: _vm.loadingData
            }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || _vm.$t('general.na'))+" ")]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeValue(item.createdAt, 'long'))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('status-chip',{attrs:{"small":"","status":item.status}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('table-button',{attrs:{"link":{
                  name: 'IntegrationFilesDetails',
                  params: {
                    routeDetailId: item.id
                  }
                },"text":"general.details"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }