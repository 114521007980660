import { apiHttpService } from '@/api'
import getNested from 'get-nested'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'
import {
  getISOStringDateTimeGte,
  getISOStringDateTimeLte
} from '@/api/utils/iso_date'

const mapResponse = response => {
  return {
    createdAt: response.created_at,
    id: response.id,
    name: response.integration_file_name,
    url: response.integration_file,
    documentId: response.document_id,
    pipeline: getNested(() => response.integration_pipeline.name, ' '),
    status: response.sent_at ? 'sent' : 'processing'
  }
}

const processResponseList = list => list.map(item => {
  return mapResponse(item)
})

export default {
  async fetchDetail () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/integration-files/' + getRouteDetailId(),
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchList (queryParams) {
    let params = {}
    if (queryParams.activeFilters) {
      params = {
        id: queryParams.activeFilters.id,
        integration_pipeline: queryParams.activeFilters.pipeline,
        created_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.createdDateFrom),
        created_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.createdDateTo),
        sent: queryParams.activeFilters.status,
        document_id: queryParams.activeFilters.document
      }
    }

    setPaginationParams(queryParams, params, 'integration')

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/integration-files/',
        method: 'GET',
        params: arraySerializer(params)
      })
      const processedResponse = {
        itemCount: response.data.count,
        pageCount: response.data.total_pages,
        items: processResponseList(response.data.results)
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
