<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <custom-autocomplete
        v-model="form.pipeline"
        hide-details
        :prepend-icon="$vuetify.icons.values.trendingUp"
        :items="activeWorkspacePipelines"
        item-value="id"
        item-text="name"
        :chips="$vuetify.breakpoint.mdAndUp"
        :deletable-chips="$vuetify.breakpoint.mdAndUp"
        :small-chips="$vuetify.breakpoint.mdAndUp"
        :loading="getPipelinesLoadingStatus"
        :label="$t('integration.pipeline')"
        multiple
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <custom-text-field
        v-model="form.document"
        hide-details
        :prepend-icon="$vuetify.icons.values.document"
        type="number"
        :label="$t('integration.document')"
        clearable
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateFromProps"
        :data="form"
        :locale="locale"
        reference="createdDateFrom"
        @close="createdDateFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateToProps"
        :data="form"
        :locale="locale"
        reference="createdDateTo"
        @close="createdDateToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <custom-select
        v-model="form.status"
        hide-details
        :prepend-icon="$vuetify.icons.values.integrationSend"
        :items="sendingStatuses"
        item-value="id"
        item-text="name"
        clearable
        :label="$t('integration.status')"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  props,
  getWorkspacePipelines,
  mounted,
  beforeDestroy,
  computedProperties,
  commonMethods
} from '@/mixins/filters'
import DatePicker from './picker/Date'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'IntegrationFilters',
  components: {
    DatePicker,
    CustomTextField,
    CustomAutocomplete,
    CustomSelect
  },
  mixins: [
    props,
    getWorkspacePipelines,
    mounted,
    beforeDestroy,
    computedProperties,
    commonMethods
  ],
  data () {
    return {
      sendingStatuses: [
        { id: 'true', name: this.$t('integration.sent') },
        { id: 'false', name: this.$t('integration.pending') }
      ],
      form: {
        createdAtFrom: null,
        createdAtTo: null,
        document: null,
        pipeline: null,
        status: null
      },
      createdDateFromProps: {
        menu: false,
        label: 'integration.createdDateFrom'
      },
      createdDateToProps: {
        menu: false,
        label: 'integration.createdDateTo'
      }
    }
  }
}
</script>
