<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <filters-side-sheet
          :expand-table-filters.sync="expandTableFilters"
          :loading-data="loadingData"
          :filters-are-active="filtersAreActive"
        >
          <template v-slot:title>
            {{ $t('general.filters.received') }}
          </template>
          <template v-slot:filters>
            <integration-filters
              :context="viewContext"
              :loading-data="loadingData"
              :expand-table-filters.sync="expandTableFilters"
              :active-filters.sync="activeFilters"
            />
          </template>
        </filters-side-sheet>
        <table-toolbar
          :context="tableToolbarContext"
          :loading-data="loadingData"
          :expand-table-filters.sync="expandTableFilters"
          :filters-are-active="filtersAreActive"
          @refreshData="getTableData"
        />
        <v-card outlined>
          <v-card-text v-show="filtersAreActive">
            <filter-chips :active-filters="activeFilters">
              <template v-slot:label="{ item }">
                {{ $t('integration.' + item) }}
              </template>
            </filter-chips>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-data-table
              :loading="loadingData"
              :headers="headers"
              :items="tableData.items"
              :options.sync="paginationOptions"
              :server-items-length="tableData.itemCount"
              :style="tableWrapStyle"
              :footer-props="{
                itemsPerPageOptions: paginationOptions.itemsRange,
                showCurrentPage: true,
                showFirstLastPage: true,
                disablePagination: loadingData,
                disableItemsPerPage: loadingData
              }"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name || $t('general.na') }}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ getDateTimeValue(item.createdAt, 'long') }}
              </template>
              <template v-slot:item.status="{ item }">
                <status-chip
                  small
                  :status="item.status"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <table-button
                  :link="{
                    name: 'IntegrationFilesDetails',
                    params: {
                      routeDetailId: item.id
                    }
                  }"
                  text="general.details"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api/modules/integration'
import { listViews } from '@/mixins/list_views'
import { getDateTimeValue } from '@/mixins/dates'
import StatusChip from '@/components/chips/StatusChip'
import FiltersSideSheet from '@/components/side_sheets/FiltersSideSheet'
import IntegrationFilters from '@/components/filters/integration/Filters'
import FilterChips from '@/components/chips/FilterChips'
import TableToolbar from '@/components/toolbars/TableToolbar'
import TableButton from '@/components/buttons/TableButton'

export default {
  name: 'Integration',
  components: {
    StatusChip,
    FiltersSideSheet,
    IntegrationFilters,
    FilterChips,
    TableToolbar,
    TableButton
  },
  mixins: [
    listViews,
    getDateTimeValue
  ],
  computed: {
    headers () {
      return [
        { text: this.$t('general.details'), sortable: false, value: 'actions', width: 48 },
        { text: this.$t('integration.name'), sortable: false, value: 'name' },
        { text: this.$t('integration.status'), sortable: false, value: 'status' },
        { text: this.$t('integration.createdAt'), sortable: true, value: 'createdAt' },
        { text: this.$t('integration.document'), sortable: false, value: 'documentId' },
        { text: this.$t('integration.pipeline'), sortable: false, value: 'pipeline' }
      ]
    }
  },
  methods: {
    async getTableData () {
      const params = {
        ...this.paginationOptions,
        activeFilters: Object.assign({}, this.activeFilters)
      }
      this.loadingData = true
      const result = await api.fetchList(params)
      Object.assign(this.tableData, result)
      this.loadingData = false
    }
  }
}
</script>
